import React from 'react';
import styled from 'styled-components';
import { Link, graphql } from 'gatsby';

import SEO from 'components/utils/seo';
import Layout from 'components/utils/layout';
import GridView from 'components/utils/gridView';
import StyledPost from 'components/modules/styledPost';
import HiglightedText from 'components/atoms/highlightText';
import Button from 'components/atoms/button';

const StyledSection = styled.section`
  margin-top: -2.5rem;
  padding-bottom: 10rem;
  display: grid;
  grid-auto-rows: minmax(min-content, 40rem);
  grid-template-columns: repeat(4, 1fr);
  grid-row-gap: 10rem;

  ${({ theme: { mq } }) => mq.tablet} {
    grid-template-columns: 1fr;
  }
`;

const StyledInfoContainer = styled.div`
  position: relative;
  grid-column: 2 / 4;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;

  h2 {
    margin: 10rem 0 5rem;
  }

  ${({ theme: { mq } }) => mq.bigTablet} {
    h2 {
      margin: 10rem 5rem 5rem;
    }
  }

  ${({ theme: { mq } }) => mq.tablet} {
    margin-top: 7rem;
    h2 {
      margin: 5rem 5rem;
    }
  }
  ${({ theme: { mq } }) => mq.bigPhone} {
    grid-column: 1 / -1;
    h2 {
      margin: 2.8rem;
      text-align: left;
      line-height: 1.3;
    }
  }
`;

const StyledLink = styled(Link)`
  display: block;
  height: 100%;
  min-height: 40rem;
  margin: 2.55rem;
  grid-column: 2/5;
  :nth-of-type(2n + 1) {
    grid-column: 1/4;
  }
  & > * {
    height: 100%;
  }

  ${({ theme: { mq } }) => mq.tablet} {
    grid-column: 1 / -1;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const StyledInfo = styled.p`
  padding: 2rem 3rem;
  font-size: ${({ theme: { fontSize } }) => fontSize.m};
  border-radius: 2rem;
  background-color: ${({ theme: { color } }) => color.devider};
  ${({ theme: { mq } }) => mq.bigTablet} {
    margin: 0 5rem;
    font-size: ${({ theme: { fontSize } }) => fontSize.blogM};
  }
  ${({ theme: { mq } }) => mq.bigPhone} {
    margin: 5rem 2.8rem;
  }
`;

const StyledHeading = styled.h3`
  grid-column: 2/4;

  ${({ theme: { mq } }) => mq.tablet} {
    margin: 5rem 5rem;
  }
`;

const ButtonWrapper = styled.div`
  width: 100%;
  margin: 5rem 0 5rem;
  display: flex;
  justify-content: space-around;

  & > * {
    margin-top: 0;
  }

  ${({ theme: { mq } }) => mq.bigPhone} {
    flex-direction: column;
    align-items: center;
  }
`;

const RegularInfo = styled.p`
  font-size: ${({ theme: { fontSize } }) => fontSize.blogM};
  grid-column: 1 / -1;
`;

const BlogPage = ({ data, pageContext }) => {
  const posts = data.allMdx.edges;

  return (
    <Layout>
      <SEO title='Blog' image='logo' />
      <GridView page='blog'>
        <StyledInfoContainer>
          <h2>
            Zbiór najważniejszych <HiglightedText>informacji!</HiglightedText>
          </h2>
          <StyledInfo>
            Jeżeli tu jesteś to znaczy, że chcesz zarabiać w internecie! Wiem,
            że z początku temat ten może wydawać się bardzo obszerny, ale nie
            obawiaj się, jestem tutaj, żeby Ci pomóc. <br />
            Poniżej zapisałem dla Ciebie kilka najważniejszych postów jakie
            napisałem, które pomogą Ci zacząć swoją przygodę. Jężeli ogarniesz
            już z czym to się wszystko je zapraszam do przeczytania pozostałych
            postów!
          </StyledInfo>
        </StyledInfoContainer>
        <StyledHeading>Lista przydatnych artykułów</StyledHeading>
        <StyledSection>
          {posts.length > 0 ? (
            posts.map(post => (
              <StyledLink
                key={post.node.frontmatter.title}
                to={`/blog${post.node.fields.slug}`}
              >
                <StyledPost post={post} enviroment='blog' />
              </StyledLink>
            ))
          ) : (
            <RegularInfo>
              Niestety jeszcze tu nic nie ma! Wróć później na pewno znajdziesz
              tutaj coś ciekawego!
            </RegularInfo>
          )}
        </StyledSection>
        <StyledInfoContainer>
          <StyledInfo>
            Mam nadzieje, że powyższe informacje pomogły Ci nabarać pewności
            siebie oraz biegłości w operowaniu po platformach i systemach, które
            pomogą Ci zarobić. Jeżeli masz jakiekolwiek pytania napisz mnie lub
            poszukaj informacji w innych moich postach!
          </StyledInfo>
          <ButtonWrapper>
            <Button to='/blog'>Do wszystkich postów!</Button>
            <Button to='/kontakt'>Napisz do mnie!</Button>
          </ButtonWrapper>
        </StyledInfoContainer>
      </GridView>
    </Layout>
  );
};

export const query = graphql`
  query {
    allMdx(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { important: { eq: "tak" } } }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            date
            description
            authors {
              author
            }
            tags
            image {
              childImageSharp {
                fluid(maxWidth: 720) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          excerpt
        }
      }
    }
  }
`;

export default BlogPage;
